

function Offer() {
    return (
        <div className="row g-0">
            <div className="col-sm-12 col-md-4 ">
                <div className="card card-body m-4 color-offer-card">
                    <div className="card-title fs-5 fw-bold offer-title">
                        KSIĘGA PRZYCHODÓW I ROZCHODÓW
                    </div>
                    <ul className="list-group list-font">
                        <li className="list-group-item color-offer-list">
                            <i className="bi bi-arrow-right pe-3"></i>
                            Prowadzenie księgi podatkowej
                        </li>
                        <li className="list-group-item color-offer-list">
                            <i className="bi bi-arrow-right pe-3"></i>
                            Prowadzenie rejestru zakupów i sprzedaży VAT
                        </li>
                        <li className="list-group-item color-offer-list">
                            <i className="bi bi-arrow-right pe-3"></i>
                            Prowadzenie ewidencji środków trwałych
                        </li>
                        <li className="list-group-item color-offer-list">
                            <i className="bi bi-arrow-right pe-3"></i>
                            Sporządzanie rozliczeń rocznych PIT
                        </li>
                        <li className="list-group-item color-offer-list">
                            <i className="bi bi-arrow-right pe-3"></i>
                            Wypełnianie wniosków kredytowych
                        </li>
                        <li className="list-group-item color-offer-list">
                            <i className="bi bi-arrow-right pe-3"></i>
                            Sprawozdania GUS
                        </li>
                    </ul>
                </div>
            </div>

            <div className="col-sm-12 col-md-4">
                <div className="card card-body m-4 color-offer-card2">
                    <div className="card-title fs-5 fw-bold offer-title">
                        RYCZAŁT EWIDENCJONOWANY
                    </div>
                    <ul className="list-group list-font">
                        <li className="list-group-item color-offer-list">
                            <i className="bi bi-arrow-right pe-3"></i>
                            Prowadzenie ewidencji przychodu
                        </li>
                        <li className="list-group-item color-offer-list">
                            <i className="bi bi-arrow-right pe-3"></i>
                            Rozliczenie wysokości podatku PPE
                        </li>
                        <li className="list-group-item color-offer-list">
                            <i className="bi bi-arrow-right pe-3"></i>
                            Prowadzenie rejestru zakupów i sprzedaży VAT
                        </li>
                        <li className="list-group-item color-offer-list">
                            <i className="bi bi-arrow-right pe-3"></i>
                            Rozliczenia roczne PIT-28
                        </li>
                        <li className="list-group-item color-offer-list">
                            <i className="bi bi-arrow-right pe-3"></i>
                            Ewidencje środków trwałych
                        </li>
                    </ul>
                </div>
            </div>
            <div className="col-sm-12 col-md-4">
                <div className="card card-body m-4 color-offer-card">
                    <div className="card-title fs-5 fw-bold offer-title">
                        KADRY I PŁACE
                    </div>
                    <ul className="list-group list-font">
                        <li className="list-group-item color-offer-list">
                            <i className="bi bi-arrow-right pe-3"></i>
                            Prowadzenie kompleksowej dokumentacji pracowniczej
                        </li>
                        <li className="list-group-item color-offer-list">
                            <i className="bi bi-arrow-right pe-3"></i>
                            Sporządzanie umów o pracę lub umów o zlecenie
                        </li>
                        <li className="list-group-item color-offer-list">
                            <i className="bi bi-arrow-right pe-3"></i>
                            Sporządzanie list płac oraz rachunków do umów o
                            zlecenie
                        </li>
                        <li className="list-group-item color-offer-list">
                            <i className="bi bi-arrow-right pe-3"></i>
                            Sporządzanie deklaracji rozliczeniowych do ZUS
                        </li>
                        <li className="list-group-item color-offer-list">
                            <i className="bi bi-arrow-right pe-3"></i>
                            Obsługa konta klienta na platformie PUE ZUS
                        </li>
                        <li className="list-group-item color-offer-list">
                            <i className="bi bi-arrow-right pe-3"></i>
                            Sporządzanie informacji rocznych
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Offer
