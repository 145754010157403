import React from "react"

import papierkiY from "./pictures/papierki-y.jpg"
import ksiegowaY from "./pictures/ksiegowa-y.jpg"
import skarbonkaY from "./pictures/skarbonka-y.jpg"
import easyStonksY from "./pictures/easy-stonks-y.jpg"

export default function MainPage() {
    return (
        <div className="row g-0 m-4 justify-content-center">
            <div className="col-lg-12 col-xl-5 card m-3 card-background1">
                <div className="row g-0">
                    <div
                        className="col-4 d-none d-sm-block"
                        style={{ maxHeight: "14rem", overflow: "hidden" }}
                    >
                        <img
                            src={papierkiY}
                            className="rounded img-fluid"
                            alt="background"
                            style={{}}
                        ></img>
                    </div>
                    <div className="col">
                        <div className="card-body card-text">
                            Moje Biuro Rachunkowe świadczy usługi dla małych i
                            średnich firm w zakresie prowadzenia uproszczonej
                            księgowości i prowadzenia spraw kadrowo-płacowych
                            jak również ZUS.
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-12 col-xl-5 card m-3 card-background2">
                <div className="row g-0">
                    <div
                        className="col-4 d-none d-sm-block order-2"
                        style={{maxHeight: "14rem", overflow: "hidden" }}
                    >
                        <img
                            src={ksiegowaY}
                            className="rounded img-fluid"
                            alt="background"
                            style={{}}
                        ></img>
                    </div>

                    <div className="col order-1">
                        <div className="card-body card-text">
                            Jestem świadoma, że w gąszczu obecnych przepisów
                            podatkowych i ubezpieczeniowych można czuć się
                            niepewnie, zwłaszcza, gdy jest się początkującym
                            przedsiębiorcą. Dlatego pomagam klientom od samego
                            początku w założeniu działalności gospodarczej.
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-12 col-xl-5 card m-3 card-background1">
                <div className="row g-0">
                    <div
                        className="col-4 d-none d-sm-block"
                        style={{ maxHeight: "14rem", overflow: "hidden" }}
                    >
                        <img
                            src={skarbonkaY}
                            className="rounded img-fluid"
                            alt="background"
                            style={{}}
                        ></img>
                    </div>
                    <div className="col">
                        <div className="card-body card-text">
                            W swej codziennej pracy stawiam na indywidualne
                            podejście do klienta oraz rzetelność i terminowość
                            świadczonych usług.
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-12 col-xl-5 card m-3 card-background2">
                <div className="row g-0">
                    <div
                        className="col-4 d-none d-sm-block order-2"
                        style={{ maxHeight: "14rem", overflow: "hidden" }}
                    >
                        <img
                            src={easyStonksY}
                            className="rounded img-fluid"
                            alt="background"
                            style={{}}
                        ></img>
                    </div>
                    <div className="col">
                        <div className="card-body card-text">
                            Ciągle podnoszę swoje kompetencje zawodowe poprzez
                            udział w licznych szkoleniach.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
