import React from "react"
import { Outlet } from "react-router-dom"

import Navbar from "./Navbar"
import Footer from "./Footer"
import CookiesConsent from "./cookies/CookiesConsent"

export default function Layout() {
	return (
		<div className="d-flex flex-column min-vh-100 MainLayout">
			<Navbar />
			<Outlet />
			<CookiesConsent />
			<Footer />
		</div>
	)
}
