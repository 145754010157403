import { Link } from "react-router-dom"

import logo from "./pictures/Logo biuro obrazek.svg"

function Footer() {
	const year = new Date().getFullYear()
	return (
		<nav className="navbar footer mt-auto">
			<div className="d-flex align-items-center">
				<img
					src={logo}
					alt="Logo"
					width="40"
					height="40"
					className="mx-3"
				/>
				<div className="copyright-text">Copyright &copy; {year}</div>
			</div>

			<div className="nav-links">
				<ul className="nav nav-pills">
					<li className="nav-item footer-border-top">
						<Link to="politykaCookies" className="nav-link footer-link">
							Polityka Cookies
						</Link>
					</li>
					<li className="nav-item footer-border-top">
						<Link to="/" className="nav-link footer-link" aria-current="page">
							O Biurze
						</Link>
					</li>
					<li className="nav-item footer-border-top">
						<Link to="oferta" className="nav-link footer-link">
							Oferta
						</Link>
					</li>

					<li className="nav-item footer-border-top">
						<Link to="kontakt" className="nav-link footer-link">
							Kontakt
						</Link>
					</li>
				</ul>
			</div>
		</nav>
	)
}

export default Footer
