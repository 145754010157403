import GoogleMaps from "./GoogleMaps"

function Contact() {
    return (
        <div className="row g-0">
            <div className="col-sm-12 col-md-6">
                <div className="card  m-4 contact-card-background">
                    <div className="card-body">
                        <div className="card-title fs-5 fw-bold offer-title">
                            Kontakt
                        </div>
                        <ul className="list-group list-font">
                            <li className="list-group-item contact-card-background2">
                                <i className="pe-2 bi bi-geo-alt-fill"></i>
                                ul. Henryka Dembińskiego 41
                                <br />
                                64-100 Leszno
                            </li>
                            <li className="list-group-item contact-card-background2">
                                <i className="pe-2 bi bi-telephone-fill"></i>609
                                390 590
                            </li>
                            <li className="list-group-item contact-card-background2">
                                <i className="pe-2 bi bi-envelope-fill"></i>
                                biuro.kamieniarz@gmail.com
                            </li>

                            <li className="list-group-item contact-card-background2">
                                <i className="pe-2 bi bi-door-open-fill"></i>
                                Biuro działa w godzinach popołudniowych,
                                jednakże kontaktować się można ze mną mailowo
                                lub telefonicznie również w godzinach porannych.
                            </li>
                            <li className="list-group-item contact-card-background2">
                                NIP: 698 157 46 65
                            </li>
                            <li className="list-group-item contact-card-background2">
                                REGON 388 627 534
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-6">
                <div className="card card-body m-4 maps-color">
                    <GoogleMaps />
                </div>
            </div>
        </div>
    )
}

export default Contact
