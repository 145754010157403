import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"


export default function CookiesConsent(props) {
	const [closed, setClosed] = useState(false) // check for localstorage
	const [allowedAnalytics, setAllowedAnalytics] = useState(null)
	const [analayticsCheckboxState, setAnalayticsCheckboxState] =
		useState(false)

	// check for changes in consent and modify rg4js accordingly
	useEffect(() => {
		setAllowedAnalytics(localStorage.getItem("allowedAnalytics"))

		if (allowedAnalytics !== null && allowedAnalytics !== "null") {
			console.log("close consent")
			setClosed(true)
		}
	}, [allowedAnalytics])

	const onAcceptClick = () => {
		setClosed(false)
		localStorage.setItem("allowedAnalytics", true)
		setAllowedAnalytics(true)
	}

	const onRejectClick = () => {
		setClosed(true)
		localStorage.setItem("allowedAnalytics", false)
		setAllowedAnalytics(false)
	}

	const onCheckboxClick = (id) => {
		setAnalayticsCheckboxState(!analayticsCheckboxState)
	}

	const onSaveClick = () => {
		localStorage.setItem("allowedAnalytics", analayticsCheckboxState)
		setAllowedAnalytics(analayticsCheckboxState)
	}

	const renderDetailsModal = () => {
		const renderCookieType = () => {
			return cookieDetailsData.map((obj) => {
				return (
					<li className="list-group-item" key={obj.id}>
						<div className="fw-bold">{obj.title}</div>
						<div className="row">
							<div className="col-10">{obj.desc}</div>

							<div className="col form-check form-switch d-flex align-items-center justify-content-center">
								<input
									disabled={obj.id === 0 ? true : false}
									defaultChecked={obj.id === 0 ? true : false}
									type="checkbox"
									className="p-2 form-check-input"
									id={obj.id}
									onClick={() => onCheckboxClick(obj.id)}
								></input>
							</div>
						</div>
					</li>
				)
			})
		}
		return (
			<div
				className="modal fade"
				id="cookieDetailsModal"
				tabIndex="-1"
				aria-labelledby="cookieDetailsModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h1
								className="modal-title fs-5"
								id="cookieDetailsModalLabel"
							>
								Zgody na pliki cookies
							</h1>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div className="modal-body">
							<ul className="list-group">{renderCookieType()}</ul>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-primary p-1"
								data-bs-dismiss="modal"
								onClick={onSaveClick}
							>
								Zapisz
							</button>
						</div>
					</div>
				</div>
			</div>
		)
	}

	return closed ? null : (
		<div>
			<div className="fixed-bottom card m-3 mb-5">
				<div className="card-body row">
					<div className="col-12 col-md-6 col-lg-7 col-xl-8">
						W celu prawidłowego działania strony oraz analizy ilości
						odwiedzin wykorzystuję pliki cookies. Po kliknięciu w
						przycisk "szczegóły" możesz otworzyć menu, które
						umożliwia wyrażenie zgód na wybrane pliki cookie. Jeśli
						chcesz wiedzieć więcej, zobacz
						<Link to={"politykaCookies"}> Politykę Cookies</Link>.
					</div>
					<div className="col d-flex justify-content-center align-items-center">
						<div className="">
							<div
								className="m-1 p-1 btn btn-outline-secondary"
								data-bs-toggle="modal"
								data-bs-target="#cookieDetailsModal"
							>
								Szczegóły
							</div>
							<div
								className="m-1 p-1 btn btn-outline-danger"
								onClick={onRejectClick}
							>
								Odrzuć wszystkie
							</div>
							<div
								className="m-1 p-2 btn btn-success"
								onClick={onAcceptClick}
							>
								Akceptuję
							</div>
						</div>
					</div>
				</div>
			</div>
			{renderDetailsModal()}
		</div>
	)
}

const cookieDetailsData = [
	{
		id: 0,
		title: "Niezbędne pliki cookie",
		desc: "Te pliki cookie umożliwiają działanie podstawowych funkcji, takie jak zabezpieczenia i prawidłowe wyświetlanie strony. Tych plików cookie nie można wyłączyć.",
	},
	// {
	// 	id: 1,
	// 	title: "Marketingowe pliki cookie",
	// 	desc: "Te pliki cookie służą do śledzenia skuteczności reklam w celu zapewnienia bardziej dopasowanych usług i wyświetlania reklam lepiej odpowiadających Twoim zainteresowaniom.",
	// },
	// {
	// 	id: 2,
	// 	title: "Funkcjonalne pliki cookie",
	// 	desc: "Te pliki cookie zbierają dane w celu zapamiętania indywidualnych wyborów użytkowników i zapewnienia im lepszych oraz bardziej spersonalizowanych doświadczeń.",
	// },
	{
		id: 3,
		title: "Analityczne pliki cookie",
		desc: "Te pliki cookie pomagają mi zrozumieć, w jaki sposób użytkownicy wchodzą w interakcję z moją stroną, pomagają też wykrywać błędy i zapewniają lepsze ogólne statystyki.",
	},
]
