import React from "react"
import ReactDOM from "react-dom/client"
import { CookiesProvider } from "react-cookie"
import HttpsRedirect from "react-https-redirect"
import { BrowserRouter } from "react-router-dom"


import App from "./App"

const el = document.getElementById("root")
const root = ReactDOM.createRoot(el)


root.render(
	<HttpsRedirect>
		<CookiesProvider>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</CookiesProvider>
	</HttpsRedirect>
)
