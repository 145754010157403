import { Link } from "react-router-dom"
import logo from "./pictures/Logo biuro obrazek.svg"

function Navbar() {
    return (
        <div>
            <nav className="navbar">
                <div className="">
                    <Link
                        to="/"
                        className="d-flex align-items-center navbar-brand ms-4"
                    >
                        <img
                            src={logo}
                            alt="Logo"
                            width="60"
                            height="65"
                            className=""
                        />
                        <div className="logo-text lh-sm ps-4 fw-bold">
                            BIURO
                            <br />
                            RACHUNKOWE
                            <br />
                            ALINA
                            <br />
                            KAMIENIARZ
                        </div>
                    </Link>
                </div>
                <div className="nav-links me-3">
                    <ul className="nav nav-pills">
                        <li className="nav-item nav-border-bottom">
                            <Link
                                to="/"
                                className="nav-link navbar-link"
                                aria-current="page"
                            >
                                O Biurze
                            </Link>
                        </li>
                        <li className="nav-item nav-border-bottom">
                            <Link
                                to="oferta"
                                className="nav-link navbar-link"
                                aria-current="page"
                            >
                                Oferta
                            </Link>
                        </li>
                        <li className="nav-item nav-border-bottom">
                            <Link
                                to="kontakt"
                                className="nav-link navbar-link"
                                aria-current="page"
                            >
                                Kontakt
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    )
}

export default Navbar
