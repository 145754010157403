import React, { useRef, useEffect } from "react"
import { Wrapper, Status } from "@googlemaps/react-wrapper"

export default function GoogleMaps() {
	const renderMap = (status) => {
		switch (status) {
			case Status.LOADING:
				return <div>Tutaj będzie Spinner ładowania mapy google</div>
			case Status.FAILURE:
				return <div>Błąd ładowania mapy google</div>
			case Status.SUCCESS:
				return (
					<div>
						<MyMapComponent
							center={{ lat: 51.8536226, lng: 16.5566302 }}
							zoom={14}
						>
							<Marker lat={51.8536226} lng={16.5566302} />
						</MyMapComponent>
					</div>
				)
			default:
				return <div>Błąd ładowania mapy google</div>
		}
	}

	return (
		<Wrapper
			apiKey="AIzaSyBa_YELKKKwGS_O1HESs-Sf39Cjj-FdUaE"
			render={renderMap}
		/>
	)
}

const MyMapComponent = ({ center, zoom }) => {
	const ref = useRef()

	useEffect(() => {
		const map = new window.google.maps.Map(ref.current, {
			center,
			zoom,
		})
        new window.google.maps.Marker({
            position: center,
            map: map,
          });
	})

	return (
		<div className="d-flex ">
			<div className="vw-100 vh-100 p-3 m-2" ref={ref} id="map" />
		</div>
	)
}

const Marker = (props) => {
	return <div className="SuperAwesomePin"></div>
}
