import { useEffect } from "react"
import { Routes, Route, useLocation } from "react-router-dom"

import "bootstrap-icons/font/bootstrap-icons.css"

import Layout from "./components/Layout"
import MainPage from "./components/MainPage"
import Contact from "./components/Contact"
import Offer from "./components/Offer"
import CookiesPolicy from "./components/cookies/CookiesPolicy"
import "./components/css/App.css"

function App() {
	let location = useLocation()

	useEffect(
		function () { },
		[location]
	)

	return (
		<Routes>
			<Route path="/" element={<Layout />}>
				<Route index element={<MainPage />} />
				<Route path="kontakt" element={<Contact />} />
				<Route path="oferta" element={<Offer />} />
				<Route path="politykaCookies" element={<CookiesPolicy />} />
			</Route>
		</Routes>
	)
}

export default App
